<div class="container-fluid">
    <div class="form-container-top">
        <div class="form-container" (keydown.enter)="signup()">
            <div class="form-header mb-4">
                <h1 id="header" class="fs-3"> Create Account </h1>
            </div>
            <div class="form-inputs-container my-2 mb-0">
                <div class="form-inputs">
                    <div class="form-input-email my-4">
                        <input class="form-control" id="email" name="email" (keydown.space)="$event.preventDefault()"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" [(ngModel)]="email"
                            placeholder="Email">
                    </div>
                    <div class="form-input-password my-4">
                        <input [type]="show_password ? 'text' : 'password'" class="form-control" id="password"
                            name="password" [(ngModel)]="password" placeholder="Password">
                        <i (click)="show_password = !show_password"
                            [ngClass]="show_password ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                    </div>
                    <div class="form-input-password my-4">
                        <input [type]="show_cpassword ? 'text' : 'password'" class="form-control" id="cpassword"
                            name="password" [(ngModel)]="cpassword" placeholder="Confirm Password">
                        <i (click)="show_cpassword = !show_cpassword"
                            [ngClass]="show_cpassword ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                        <div class="error-field">
                            <div *ngIf="(cpassword && (password != cpassword))" class="aler alert-danger">
                                <div [hidden]="!(password != cpassword)" class="validation fs-6" style="margin-top: 0">
                                    Password do not match
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="after-input-container my-1">
                <div class="inner-content">
                    <div class="keep-me-logged">
                        <input type="checkbox" class="me-1" id="terms" name="terms" ([ngModel])="terms"
                            (change)="agreeTerms()">
                        <label for="keep-me-logged" class="fs-7 ms-1"> I agree to the terms and privacy policy </label>
                    </div>
                    <div class="forgot-password">
                        <a [routerLink]="['/forgot-password']" class="fs-7" id="forgot-password"
                            style="color: var(--light-pink);"> Forgot Password </a>
                    </div>
                </div>
            </div>
            <!-- <div class="form-footer fs-6">
				<span> New User ? <span id="create-link"> Create Account </span> </span>
			</div> -->
            <div class="btn submit-button text-center" (click)="signup()">
                <p class="m-0"> Submit </p>
            </div>
            <div>
                <p class="text-center m-0 fs-6"> Already have an account?<a [routerLink]="['/login']"
                        style="cursor: pointer; color: var(--light-pink);"> Sign In!!</a> </p>
                <!-- <img class="btnImage" src="../../assets/LogInButton-2x.png"> -->
            </div>
        </div>
    </div>
</div>



<!-- Footer -->
<div class="container-fluid" id="container2">
    <img src="../../assets/footer-triangle.png" class="footer-triangle">
    <div class="fixed-bottom"></div>
</div>
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import {  NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	email: any;
	password: any;
	user_name: any;		// set from localStorage
	isChecked: boolean = true
	is_logged_in: boolean = false;
	show_password: boolean
  constructor(
    private api: ApiService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
  ) { }

  ngOnInit(): void {
	  console.log("in login")
  }

  OnEnterPress(event: any)
	{
		if(event.keyCode == 13)
		{
			this.login()
		}
	}

	login()
	{
		console.log('User Log In...')
		this.spinner.show()
		if(!this.email || !this.password)
		{
			this.toastr.error('All Fields are required. ','Error!');
			this.spinner.hide()
		}
		else
		{
			let data = {
				email: this.email,
				password: this.password,
				role:'parent'
			}
			console.log("data to be send",data)
			this.api.loginUser(data).then( (res: any) =>
			{
				console.log("response getting ----[]",res)
				if(res.status == 200 && res.data.role == "parent")
				{
					console.log(res)	// response data - testing
					this.is_logged_in = true;
					this.api.userData=res.data
					localStorage.setItem('userData',JSON.stringify(res.data))
					localStorage.setItem('role', res.data.role)
					localStorage.setItem('_id',res.data._id)
					console.log(typeof res.data.firstName)
					let profileComplete= res.data.firstName == '' || null || undefined ? 'false' : 'true'
					localStorage.setItem('profileComplete',profileComplete)
					if(profileComplete){
						this.user_name = `${res.data.firstName.charAt(0).toUpperCase()}${res.data.firstName.slice(1)} ${res.data.lastName.charAt(0).toUpperCase()}${res.data.lastName.slice(1)}`
						localStorage.setItem('user_name', this.user_name)
					}
					
					this.api.setAccessToken(res.data.confirmtoken) // store access token

					//this.toastr.success(`Welcome back ${this.user_name}. `, "Success")
					this.router.navigate(['/dashboard'])
					this.spinner.hide()
				}
				else if(res.status != 200)
				{
					this.toastr.error(res.message, "Error !")
				}
				else
				{
					this.toastr.error("Authorization Error !", "Error !")
				}
				this.spinner.hide()
			})
		}
	}

}

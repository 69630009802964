<div class="container-fluid">
	<div class="row">
		<div class="header col-md-12">
			<div class="col-6 float-start">
				<img class="logo-image" src="../../assets/logo-image.png" (click) = "home()">
				<img class="logo-text" src="../../assets/logo-text.png" (click) = "home()">
			</div>
			<div class="float-end">
				<img class="header-triangle float-end" src="../../assets/header-triangle.png">
			</div>
		</div>
	</div>
</div>
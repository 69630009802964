import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  email: any;
  password: any;
  user_name: any;		// set from localStorage
  cpassword: any
  is_logged_in: boolean = false;
  terms = false

  show_password: boolean
  show_cpassword: boolean
  constructor(
    private api: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

  }

  OnEnterPress(event: any)
	{
		if(event.keyCode == 13)
		{
			this.signup()
		}
	}

  agreeTerms()
  {
    this.terms = !this.terms
    console.log(this.terms)
  }

  signup() {
    this.spinner.show()
    if (!this.email || !this.password || !this.cpassword) {
      this.toastr.error('All Fields are required. ', 'Error!');
      this.spinner.hide()
    }
    else {
      if (this.password != this.cpassword) {
        this.toastr.error("Password and Confirm password do not match")
        this.spinner.hide()
      }
      else {
        if (this.terms == false) {
          this.toastr.error("please agree to the terms and privacy policy")
        }
        else {
          let dataToSend = {
            email: this.email,
            password: this.password
          }
          this.api.signUp(dataToSend).then((res: any) => {
            console.log("response getting ----[]", res)
            if (res.status == 200 ) {
              console.log(res)
              this.toastr.success(res.message)
              this.router.navigate(['/login'])
            }
            else if (res.status != 200) {
              this.toastr.error(res.message, "Error !")
            }
          })
        }
        this.spinner.hide()
       
      }
    }
  }
}
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  	authToken: any = localStorage.getItem('token')
	baseURL: string = environment.API_SERVICE_URL
	userObject: any;


  constructor(private http: HttpClient) { }

  // --------------------------------------------------------------
	// 							Authentication
	// --------------------------------------------------------------


	set userData(user_data: any)
	{
		this.userObject = user_data
		console.log("user_datatat", this.userData)
	}

	get userData()
	{
		if (this.userObject)
		{
			return this.userObject;
		}
		else
		{
			return JSON.parse(localStorage.getItem('userData'))
		}
	}


	getAccessToken()
	{
		if(localStorage.getItem('token'))
		{
			this.authToken = localStorage.getItem('token')

		}
		return localStorage.getItem('token')
	}

	setAccessToken(token: string)
	{
		localStorage.setItem('token', token)
		console.log("User Token saved. ")
	}

	async loginUser(data: any)
	{
		console.log("Request Received...")
		return await this.http.post(this.baseURL + 'parent/login', data)
		.toPromise()
		.then(res => res)
	}


	signUp(data: any)
	{
		console.log("Request Received...",data)
		return  this.http.post(this.baseURL + 'parent/signup', data)
		.toPromise()
		.then(res => res)
	}

	profileComplete(data: any,id:any)
	{
		console.log("Request Received...",data,id)
		return  this.http.put(this.baseURL + 'parent/update-profile/'+id, data)
		.toPromise()
		.then(res => res)
	}
	

	logOut()
	{
		this.authToken = null
		localStorage.clear()
	}

	isLoggedIn()
	{
		console.log(this.getAccessToken() !== null)
		return this.getAccessToken() !== null
	}

	isLoggedOut()
	{
		return this.getAccessToken() === null
	}

	/**
	 * Forgot Password functionality
	 * @param data data to be sent to the service
	 * @returns object
	 */
	
	async forgotPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/forget-password', data)
		.toPromise()
		.then(res => res)
	}

	/**
	 *
	 * @param data data
	 * @returns object
	 */
	async resetPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/reset-password', data)
		.toPromise()
		.then(res => res)
	}
	
	setName(name: string)
	{
	  localStorage.setItem('user_name', name)
	  console.log('User Name has been changed. ')
	}
	// --------------------------------------------------------------
	// 							Profile
	// --------------------------------------------------------------

	getProfileInfo(parent_id:any){
		console.log("gettuing profile",parent_id)
		let headers: any = { headers: { Authorization: this.authToken } };
		console.log(this.baseURL+'user/getProfile/'+parent_id)
		return this.http.get(this.baseURL+'user/getProfile/'+parent_id,headers)
		.toPromise()
		.then(res => res)
	}

	updateProfileInfo(parent_id:any,data:any){
		console.log(data)
		console.log(parent_id)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.put(this.baseURL+'parent/update-profile/'+parent_id,data,headers)
		.toPromise()
		.then(res => res)
	}

	// --------------------------------------------------------------
	// 							Child Information
	// --------------------------------------------------------------

	getChild(id:any){
		
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL+'parent/getChild/'+id,headers)
		.toPromise()
		.then(res => res)
	}
	
	addStudent(data:any){
		console.log(data)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(this.baseURL+'user/search-student',data,headers)
		.toPromise()
		.then(res => res)
	}

	editStudent(student_id:any,data:any,){
		console.log(data)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.put(`${this.baseURL}parent/update-child-info/${student_id}`,data,headers)
		.toPromise()
		.then(res => res)
	}

	generatereq(data:any,parent_id:any){
		console.log(data)
		console.log(parent_id)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(this.baseURL+'parent/generate-req/'+parent_id,data,headers)
		.toPromise()
		.then(res => res)
	}
	
	// --------------------------------------------------------------
	// 							approvals 
	// --------------------------------------------------------------

	getRequest(id:any){
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL+'parent/getPendingRequest/'+id,headers)
		.toPromise()
		.then(res => res)
	}
	approveRequest(data:any,id:any){
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(this.baseURL+'parent/approve-secondary-guardian/'+id,data,headers)
		.toPromise()
		.then(res => res)
	}

	// --------------------------------------------------------------
	// 							Attendance 
	// --------------------------------------------------------------

	getChildAttendance(id:any,data:any){
		
		let headers: any = { 
			params:data,
			headers: { Authorization: this.authToken } };
		return this.http.get(`${this.baseURL}parent/get-child-attendance/${id}`,headers)
		.toPromise()
		.then(res => res)
	}


	childInformation(id:any){
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL+'teacher/student/'+id,headers)
		.toPromise()
		.then(res => res)
	}


	// ----------------------------------------------------------------
	// 								Attendance
	// ----------------------------------------------------------------
	addAttendance(parentId, data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(`${this.baseURL}parent/add-attendance/${parentId}`, data, headers)
			.toPromise()
	}


	getCarrierDomain(){
		return  this.http.get(`${this.baseURL}user/carrier-domain`)
			.toPromise()
			.then(res => res)
	}


	atendanceMetric(id: any)
	{
		console.log(id)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL + 'user/attendance-metrics/' + id, headers)
			.toPromise()
	}

	getAllStates()
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(this.baseURL + "super-admin/state", headers)
			.toPromise()
			.then(res => res)
	}
}

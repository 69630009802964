import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate
{
	constructor(
		private router: Router,
		private api: ApiService
	) { }

	canActivate()
	{
		if(this.api.isLoggedOut())
		{
			return true
		}
		else
		{
			this.router.navigate(['/dashboard'])
			return false
		}
	}
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupComponent } from './signup/signup.component';
import { LogoutGuard } from './services/logout.guard';
import { AuthGuard } from './services/auth.guard';
//import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

const routes: Routes = [
  	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	
	{
		path: 'login',
		component: LoginComponent,
		canActivate:[LogoutGuard]
	},
	{
		path: 'signup',
		component: SignupComponent,
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'forgot-password',
		component:ForgotPasswordComponent
	},
	
	{
		path: '**',
		redirectTo: 'login',
		pathMatch: 'full'
	},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

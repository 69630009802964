
<app-header></app-header>

<router-outlet></router-outlet>


 <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
	<p style="color: white">
		Loading...
	</p>
</ngx-spinner> 

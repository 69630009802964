// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_SERVICE_URL: "http://10.20.1.180:5001/",
  accessKeyId: "AKIARYV6ELZPTU6NQD7F",
  secretAccessKey: "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",
  API_SERVICE_URL: "https://backend.trackalt.com/",
  //API_SERVICE_URL: "http://10.20.20.77:5001/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

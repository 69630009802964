import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	code: any;
	password: any;
	c_password: any;
	email: string | undefined;
	emailSent = false;

	constructor(
		private api: ApiService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private router: Router
	) { }

	ngOnInit(): void {
	}

	sendEmail() {
		let data = {
			email: this.email
		}
		this.spinner.show();
		if (!this.email) {
			this.toastr.error('Please enter your email id.', 'Error');
			this.spinner.hide();
		} else {
			this.api.forgotPassword(data).then((res: any) => {
				console.log("forgot res______________", res);
				if (res.status == 200) {
					this.toastr.success(res.message, 'Success');
					this.emailSent = true;
				} else {
					this.toastr.error(res.message, 'Error');
				}
				this.spinner.hide();
			});
		}
	}

	resetPassword(data: any) {
		this.spinner.show();
		if (this.password != this.c_password) {
			this.toastr.error('Passwords do no match. ', 'Error');
			this.spinner.hide();
		} else {
			let dataToSend = {
				code: this.code,
				password: this.password
			}
			this.api.resetPassword(dataToSend).then((res: any) => {
				console.log("reset res______________", res);
				if (res.status == 200) {
					this.toastr.success(res.message, 'Success');
					this.router.navigate(['/login']);
					this.spinner.hide();
				} else {
					this.spinner.hide();
					this.toastr.error(res['message'], 'Error');
				}
			});
		}
	}

}
